import '../App.css';
import footerLogo from '../images/scroll_logo.png';

function Footer()
{
    return(
        <div className="footer">
            <div className="footer_inner">
                <div className="footer_1">
                    <img src={footerLogo} alt="PGG" width="100%" />
                    <p><b>PRIVILEGE GREEN GROVES</b> is being developed by PRIVILEGE PROJECTS as an exclusive residential layout that offers you the joy of living amidst greenery and serene environment.</p>
                </div>
                <div className="footer_2">
                    <div className="footer_heading">
                        <p>Quick Links</p>
                    </div>
                    <ul>
                        <li>
                            <a href="https://www.privilegegreengroves.in/#home">Home</a>
                        </li>
                        <li>
                            <a href="https://www.privilegegreengroves.in/#features">Features</a>
                        </li>
                        <li>
                            <a href="https://www.privilegegreengroves.in/#about">About Us</a>
                        </li>
                        <li>
                            <a href="https://www.privilegegreengroves.in/#gallery">Gallery</a>
                        </li>
                        <li>
                            <a href="https://www.privilegegreengroves.in/#contact">Contact Us</a>
                        </li>
                        <li>
                            <a href="/privacy">Privacy Policy</a>
                        </li>
                    </ul>
                </div>
                <div className="footer_3">
                    {/* <div className="footer_heading">
                        <p>Legal</p>
                    </div>
                    <ul>
                        <li><a href="/privacy">Privacy Policy</a></li>
                        <li><a href="/terms">Terms And Conditions</a></li>
                    </ul> */}
                    <div className="footer_heading1">
                        <p>Social Links</p>
                    </div>
                    <ul className="social">
                        <li>
                            <a href="https://www.facebook.com" className="fa fa-facebook"></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/" className="fa fa-instagram"></a>
                        </li>
                        <li>
                            <a href="https://www.google.com/" className="fa fa-google"></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Footer;