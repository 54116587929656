import '../App.css';
import React from 'react';
import emailjs from 'emailjs-com';
import Snackbar from 'node-snackbar';

function Home(){

    function downloadBrochure(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_qbzz3lv', 'template_u74prsp', e.target, 'user_SdHLhpnBySbzjGpy9Ivc2')
            .then((result) => {
                Snackbar.show({ pos: 'bottom-center', showAction: false, text: 'Thank you for your interest. Our brochure has been shared to your email address.' });
                e.target.reset();

                const modal = document.querySelector('#modal');

                modal.setAttribute("style","display:none;")

            }, (error) => {
                Snackbar.show({ pos: 'bottom-center', showAction: false, text: 'There was an error downloading the brochure. Please try again after sometime.' });
                e.target.reset();
            });
    }

    function showModal(e) {
        e.preventDefault();

        const modal = document.querySelector('#modal');

        modal.setAttribute("style","display:flex;")
    }

    function removeModal(e) {
        e.preventDefault();

        const modal = document.querySelector('#modal');

        modal.setAttribute("style","display:none;")
    }

    return(
        <div className="home">
            <div className="home_inner">
                <div className="home_content">
                    <h2>Affordable Plots That Fetch Big Returns</h2>
                    <a className="link" href="tel:7259139999"><b><span className="fa fa-phone"></span></b> +91 72591 39999</a>
                    <a className="link" id="whatsapp" href="https://api.whatsapp.com/send?phone=918951297649&lang=en" target="_blank" rel="noreferrer"><b><span className="fa fa-whatsapp"></span></b> +91 89512 97649</a>
                    <p className="rera">RERA No. :</p>
                    <p className="rera">PRM/KA/RERA/1265/347/PR/200121/003209</p>
                    <p className="button" onClick={showModal}>DOWNLOAD BROCHURE</p>
                </div>
            </div>
            <div className="modal" id="modal">
                <div className="modal_inner">
                    <p>Download Brochure</p>
                    <span onClick={removeModal}>&times;</span>
                    <form onSubmit={downloadBrochure}>
                        <label htmlFor="name">Name :*</label>
                        <input type="text" id="name" name="name" placeholder="Full Name" required />
                        <label v="email">Email :*</label>
                        <input type="email" name="email" id="email" placeholder="Email Address" required />
                        <label htmlFor="phone">Phone :*</label>
                        <input type="text" name="phone" id="phone" placeholder="Phone Number" maxLength="11" required/>
                        <button className="btn">DOWNLOAD BROCHURE</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Home;