import '../App.css';

function Copyright()
{
    return(
        <div className="copyright">
            <div className="copyright_inner">
                <p>Privilege Projects <span className="fa fa-copyright"></span> 2021. All Rights Reserved.</p>
                <p>Designed & Developed By <a href="http://thesapienhawk.com/">The Sapien Hawk</a></p>
            </div>
        </div>
    )
}

export default Copyright;