import '../App.css';
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

import featuresImage from '../images/features.png';

function Features()
{
    return(
        <div className="features" id="features">
            <div className="header">
                <p>OUR <span>FEATURES</span></p>
                <hr />
            </div>
            <div className="features_inner">
                <div className="features_details">
                    <div className="features_single">
                        <div className="features_single_detail">
                            <div className="features_single_count">
                                <span>1</span>
                            </div>
                            <div className="features_single_content">
                                <h3>DTCP & RERA Approved Layout</h3>
                            </div> 
                        </div>
                        <div className="features_single_detail">
                            <div className="features_single_count">
                                <span>2</span>
                            </div>
                            <div className="features_single_content">
                                <h3>8 Acres 34 Guntas of Exclusive Residential Layout</h3>
                            </div> 
                        </div>
                        <div className="features_single_detail">
                            <div className="features_single_count">
                                <span>3</span>
                            </div>
                            <div className="features_single_content">
                                <h3>Just 1.5kms from NH75</h3>
                            </div> 
                        </div>
                        <div className="features_single_detail">
                            <div className="features_single_count">
                                <span>4</span>
                            </div>
                            <div className="features_single_content">
                                <h3>158 Luxury Villa Plots Developed with Contemporary Facilities amidst Greenary</h3>
                            </div> 
                        </div>
                        <div className="features_single_detail">
                            <div className="features_single_count">
                                <span>5</span>
                            </div>
                            <div className="features_single_content">
                                <h3>30*40 and 30*50 Plots - East and West Facing</h3>
                            </div> 
                        </div>
                    </div>
                </div>
                <div className="features_layout">
                    <Gallery>
                        <Item original={featuresImage} thumbnail={featuresImage} width="1024" height="768" >
                            {({ ref, open }) => (
                                <img ref={ref} onClick={open} src={featuresImage}  width="100%" alt="Route Map"/>
                            )}
                        </Item>
                    </Gallery>
                </div>
            </div>
        </div>
    )
}

export default Features;