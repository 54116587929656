import '../App.css';
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

import image_1 from '../images/gallery_1.jpg';
import image_2 from '../images/gallery_2.jpg';
import image_3 from '../images/gallery_3.jpeg';
import image_4 from '../images/gallery_4.jpeg';
import image_5 from '../images/gallery_5.jpeg';
import image_6 from '../images/gallery_6.jpeg';
import image_7 from '../images/gallery_7.jpeg';
import image_8 from '../images/gallery_8.jpeg';
import image_9 from '../images/gallery_9.jpeg';

function Gal()
{
    return(
        <div className="gallery" id="gallery">
            <div className="header">
                <p>OUR <span>GALLERY</span></p>
                <hr />
            </div>
            <Gallery>
                <div className="gallery_inner">
                    <Item className="gallery_single" original={image_1} thumbnail={image_1} width="1024" height="768" >
                        {({ ref, open }) => (
                            <img ref={ref} onClick={open} src={image_1} width="100%" alt="Plots near KR Puram"/>
                        )}
                    </Item>
                    <Item className="gallery_single"  original={image_2} thumbnail={image_2} width="1024" height="768" >
                        {({ ref, open }) => (
                            <img ref={ref} onClick={open} src={image_2}  width="100%" alt="Plots near Whitefield"/>
                        )}
                    </Item>
                    <Item className="gallery_single"  original={image_3} thumbnail={image_3} width="1024" height="768" >
                        {({ ref, open }) => (
                            <img ref={ref} onClick={open} src={image_3}  width="100%" alt="Real Estate Office in Bangalore"/>
                        )}
                    </Item>
                    <Item className="gallery_single"  original={image_4} thumbnail={image_4} width="1024" height="768" >
                        {({ ref, open }) => (
                            <img ref={ref} onClick={open} src={image_4}  width="100%" alt="Plots near Kolar"/>
                        )}
                    </Item>
                    <Item className="gallery_single"  original={image_5} thumbnail={image_5} width="1024" height="768" >
                        {({ ref, open }) => (
                            <img ref={ref} onClick={open} src={image_5} width="100%" alt="Plots near Hoskote"/>
                        )}
                    </Item>
                    <Item className="gallery_single"  original={image_6} thumbnail={image_6} width="1024" height="768" >
                        {({ ref, open }) => (
                            <img ref={ref} onClick={open} src={image_6}  width="100%" alt="Plots near bangalore airport"/>
                        )}
                    </Item>
                    <Item className="gallery_single"  original={image_7} thumbnail={image_7} width="1024" height="768" >
                        {({ ref, open }) => (
                            <img ref={ref} onClick={open} src={image_7}  width="100%" alt="Plots near bangalore"/>
                        )}
                    </Item>
                    <Item className="gallery_single"  original={image_8} thumbnail={image_8} width="1024" height="768" >
                        {({ ref, open }) => (
                            <img ref={ref} onClick={open} src={image_8} width="100%" alt="Plots near bangalore"/>
                        )}
                    </Item>
                    <Item className="gallery_single"  original={image_9} thumbnail={image_9} width="1024" height="768" >
                        {({ ref, open }) => (
                            <img ref={ref} onClick={open} src={image_9}  width="100%" alt="Plots near bangalore"/>
                        )}
                    </Item>
                </div>
            </Gallery>
        </div>
    )
}

export default Gal;