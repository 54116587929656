import React, { Component } from 'react'
import '../App.css';
import Navbar from '../components/Navbar';
import Home from '../components/Home';
import Features from '../components/Features';
import Location from '../components/Location';
import About from '../components/About';
import Gallery from '../components/Gallery';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Copyright from '../components/Copyright';

export default class Index extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <Home />
                <Features />
                <Location />
                <About />
                <Gallery />
                <Contact />
                <Footer />
                <Copyright />
            </div>
        )
    }
}
