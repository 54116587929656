import '../App.css';
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery'

import locationImage from '../images/layout.jpeg';

function Location()
{
    return(
        <div className="location">
            <div className="header">
                <p>LOCATION AND ROUTE <span>MAP</span></p>
                <hr />
            </div>
            <div className="location_inner">
                <div className="location_2">
                    <Gallery>
                        <Item original={locationImage} thumbnail={locationImage} width="1024" height="768" >
                            {({ ref, open }) => (
                                <img ref={ref} onClick={open} src={locationImage}  width="100%" alt="Our Location"/>
                            )}
                        </Item>
                    </Gallery>
                </div>
                <div className="location_1">
                    <div>
                        <ul>
                            <li>Just 3 mins from Volvo Bus Plant</li>
                            <li>20 mins from Hoskote</li>
                            <li>15 mins from Malur</li>
                            <li>10 mins from MVJ Medical College</li>
                            <li>12 mins to Narsapura Industrial Area</li>
                            <li>60 mins from Bengaluru International Airport</li>
                        </ul>
                        <p className="location_header">This very strategic location makes the project an investment of a lifetime.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Location;