import '../App.css';
import video from '../images/location.mp4';

function About()
{
    return(
        <div className="about_main" id="about">
            <div className="header">
                <p><span>ABOUT</span> US</p>
                <hr />
            </div>
            <div className="about_main_inner">
                <div className="about_main_1">
                    <div>
                        <p><b>PRIVILEGE PROJECTS</b> as a real estate construction firm was established to develop plots,flats and houses that provide optimum value for money and customers’ satisfaction . We aim to grow bigger in this field by being part of customers’ happiness.</p>
                        <p><b>PRIVILEGE GREEN GROVES</b> is being developed by PRIVILEGE PROJECTS as an exclusive residential layout that offers the joy of living amidst greenery and a serene environment. Clear property titles and required approvals in place, coupled with its strategic location makes the project all the more attractive.</p>
                    </div>
                </div>
                <div className="about_main_2">
                    <video controls>
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    )
}

export default About