import '../App.css';
import logo from '../images/logo1.png';
import secondarLogo from '../images/logo.png';

function Navbar()
{
    return(
        <div>
            <div className="navbar_main" id="home">
                <div className="navbar_inner">
                    <div className="navbar_logo">
                        <img src={logo} alt="Privilege Green Groves" width="100%" />
                    </div>
                    <div className="navbar_right">
                        <div className="navbar_content">
                            <ul>
                                <li>
                                    <a href="https://www.privilegegreengroves.in/#home">HOME</a>
                                </li>
                                <li>
                                    <a href="https://www.privilegegreengroves.in/#features">FEATURES</a>
                                </li>
                                <li>
                                    <a href="https://www.privilegegreengroves.in/#about">ABOUT US</a>
                                </li>
                                <li>
                                    <a href="https://www.privilegegreengroves.in/#gallery">GALLERY</a>
                                </li>
                                <li>
                                    <a href="https://www.privilegegreengroves.in/#contact">CONTACT US</a>
                                </li>
                            </ul>
                        </div>
                        <div className="navbar_secondary">
                            <img src={secondarLogo} alt="Privilege Projects" width="100%" />
                        </div>
                    </div>
                    <div className="menu">
                        <div className="icon"></div>
                    </div>
                </div>
            </div>
            <div className="navbarSmall_right">
                <div className="navbarSmall_contents">
                    <ul>
                        <li>
                            <a href="https://www.privilegegreengroves.in/#home">HOME</a>
                        </li>
                        <li>
                            <a href="https://www.privilegegreengroves.in/#features">FEATURES</a>
                        </li>
                        <li>
                            <a href="https://www.privilegegreengroves.in/#about">ABOUT US</a>
                        </li>
                        <li>
                            <a href="https://www.privilegegreengroves.in/#gallery">GALLERY</a>
                        </li>
                        <li>
                            <a href="https://www.privilegegreengroves.in/#contact">CONTACT US</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Navbar;