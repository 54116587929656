import '../App.css';
import React from 'react';
import emailjs from 'emailjs-com';
import Snackbar from 'node-snackbar';

function Contact()
{
    function sendEmail(e) {
        e.preventDefault();
    
        emailjs.sendForm('service_qbzz3lv', 'template_w04n0jl', e.target, 'user_SdHLhpnBySbzjGpy9Ivc2')
            .then((result) => {
                Snackbar.show({ pos: 'bottom-center', showAction: false, text: 'Thank you for contacting us. We will get back to you soon.' });
                e.target.reset();
            }, (error) => {
                Snackbar.show({ pos: 'bottom-center', showAction: false, text: 'There was an error submitting the form. Please try again after sometime.' });
                e.target.reset();
            });
    }

    return(
        <div className="contact" id="contact">
            <div className="contact_inner">
                <div className="contact_1">
                    <div>
                        <h2>Book Your Plot Now</h2>
                        <form onSubmit={sendEmail}>
                            <label htmlFor="name">Name :</label>
                            <input type="text" id="name" name="name" placeholder="Full Name" />
                            <label htmlFor="email">Email :</label>
                            <input type="text" name="email" id="email" placeholder="Email Address" />
                            <label htmlFor="phone">Phone :</label>
                            <input type="text" name="phone" id="phone" placeholder="Phone Number" />
                            <label htmlFor="message">Message :</label>
                            <textarea id="message" placeholder="Message(Optional)" name="message"></textarea>
                            <button className="btn">CONTACT NOW</button>
                        </form>
                    </div>
                </div>
                <div className="contact_2">
                    <div className="contact_2_inner">
                        <h2>Contact Information</h2>
                        <div className="contact_2_single">
                            <div className="contact_2_header">
                                <span>Phone</span>
                            </div>
                            <p className="contact_2_content">+91 72591 39999,</p>
                            <p className="contact_2_content">+91 89512 97649</p>
                        </div>
                        <div className="contact_2_single">
                            <div className="contact_2_header">
                                <span>Email</span>
                            </div>
                            <p className="contact_2_content">privilegeprojects@gmail.com</p>
                        </div>
                        <div className="contact_2_single">
                            <div className="contact_2_header">
                                <span>Address</span>
                            </div>
                            <p className="contact_2_content">Behind Volvo Bus Plant,</p>
                            <p className="contact_2_content">Sonnanayakanahalli, Hoskote-Malur NH75,</p>
                            <p className="contact_2_content">Karnataka-562122</p>
                            <div className="gmap_canvas">
                                <iframe title="Privilege Projects Location" id="gmap_canvas" src="https://maps.google.com/maps?q=Privilege%20Green%20Groves&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;