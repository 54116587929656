import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Index from './components/Index';
import Privacy from './components/Privacy';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component={Index}/>
          <Route path="/privacy" exact component={Privacy}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
